import React from 'react';

function App() {
  React.useEffect(() => {
    var youtubeUrl = document.location.href.replace('txtyoutube', 'youtube')
    window.location.href = `https://annotate.tv/new?url=${youtubeUrl}`
  }, [])

  return <h2>Redirecting to Annotate.tv</h2>;
}

export default App;
